<template>
  <div>
    <select-shop
      :loadingButton="loadingButton"
      v-on:getData=""
    ></select-shop>
    <CCard>
    <CCardBody>
    <CRow class="mt-3">
      <CCol md="12" class="text-center">
        <h2 class="font-weight-medium text-black">{{ $t("eComSetting") }}</h2>
        <h6 class="text-description">{{ $t("connectMarketPlaces") }}</h6>
      </CCol>
    </CRow>
    <hr />

    <CRow class="mt-5">
      <CCol
        sm="12" 
        v-for="(marketplace, index) in marketplaces"
        :key="marketplace.name + index"
        class="mb-4"
        >
        <CCard>
          <CCardBody class="d-flex justify-content-between align-items-center">
            <CCol col="10">
            <div class="d-flex align-items-center">
              <img :src="marketplace.src" style="width: 10%;">
              <div style="margin-left: 15px; margin-top: 5px;">
                <h5 class="mt-2 ">{{ marketplace.name }}</h5>
                <p>{{ $t('shopeeDesc') }}</p>
              </div>
            </div>
            </CCol>
            <CCol col="2" >
            <CButton style="width: 100%;" :color="isConnected === true ? 'outline-primary' : 'outline-success'" @click="handleMarketplaceClick()">
              <i class="fa-solid fa-rotate"></i>
                {{ isConnected === true ? $t("connect") : $t("sync") }}
            </CButton>
            </CCol>
            
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <!-- Existing Modal -->
    <CModal :show.sync="isConnected" centered :title="this.$t('connectDetail')" visible @update:visible="isConnected = $event">
      <!-- <div class="text-center mb-4">
        <h5>{{ $t('storeName') }}: {{ storeInfo.name }}</h5>
        <p>{{$t('authorizeDate')}}: {{ storeInfo.authorizeDate }}</p>
      </div> -->
      <div class="p-3" style="background-color: #F4F6F9; border-radius: 8px;">
        <h6>{{ $t('storeInfo') }}</h6>
        <p>{{ $t('storeID') }}: {{ storeInfo.id }}</p>
        <p>Access Token : {{ storeInfo.token }}</p>
        <p>{{ $t('country') }} : {{ storeInfo.region }}</p>
        <p>{{ $t('authorizeBy') }}: {{ storeInfo.authorizedBy }}</p>
        <p>{{ $t('authorizeExpire') }} : {{ storeInfo.expiredDate }}</p>
      </div>
      <!-- <div class="p-3 mt-3" style="background-color: #F4F6F9; border-radius: 8px;">
        <h6>{{ $t('syncInfo') }}</h6>
        <div class="custom-control custom-checkbox custom-control-inline">
        <input type="checkbox" id="sync-product" v-model="syncProduct" class="custom-control-input" />
        <label class="custom-control-label" for="sync-product">Sync Product</label>
      </div>
      <div class="custom-control custom-checkbox custom-control-inline">
        <input type="checkbox" id="sync-order" v-model="syncOrder" class="custom-control-input" />
        <label class="custom-control-label" for="sync-order">Sync Order</label>
      </div>
      <div class="custom-control custom-checkbox custom-control-inline">
        <input type="checkbox" id="sync-stock" v-model="syncStock" class="custom-control-input" />
        <label class="custom-control-label" for="sync-stock">Sync Stock</label>
      </div>
      </div> -->
      <template #footer  >
        <div class="text-center w-100" >
          <CButton class="w-50" color="warning" @click="syncData">{{ $t('sync') }}</CButton>
        </div>
      </template>

    </CModal>

    <!-- New Modal for enterConnect -->
    <CModal :show.sync="enterConnect" centered :title="this.$t('addConnect')" visible @update:visible="enterConnect = $event">
      <div class="text-center mb-3 ">
        <p style="background-color: #EAF3FF; border-radius: 8px; padding: 15px; color: #2A7AFF;">
          <!-- <font-awesome-icon icon="info-circle" style="margin-right: 10px;" /> -->
          {{ $t('connectEcomDesc') }}
        </p>
      </div>
      <!-- <div class="p-3 mt-3" style="background-color: #F4F6F9; border-radius: 8px;">
        <h6>{{ $t('syncInfo') }}</h6>
        <div class="custom-control custom-checkbox custom-control-inline">
        <input type="checkbox" id="sync-product" v-model="syncProduct" class="custom-control-input" />
        <label class="custom-control-label" for="sync-product">Sync Product</label>
      </div>
      <div class="custom-control custom-checkbox custom-control-inline">
        <input type="checkbox" id="sync-order" v-model="syncOrder" class="custom-control-input" />
        <label class="custom-control-label" for="sync-order">Sync Order</label>
      </div>
      <div class="custom-control custom-checkbox custom-control-inline">
        <input type="checkbox" id="sync-stock" v-model="syncStock" class="custom-control-input" />
        <label class="custom-control-label" for="sync-stock">Sync Stock</label>
      </div>
      </div> -->
      <template #footer class=" mt-4">
      <div class="text-center w-100"><CButton color="primary" class="w-50" @click="authorize">{{$t('authorize')}}</CButton>
      </div>
        
      </template>
    </CModal>    
    <CModal :show.sync="showSyncLoading" centered>
          <div>
            <div class="d-flex flex-column align-items-center">
              <CSpinner color="success" size="xl" style="width: 80px; height: 80px; aspect-ratio: 1/1;" />
              <p class="mt-3">Syncing...</p>
            </div>
          </div>
          <template #footer>
          <div class="text-center w-100"><CButton color="danger" class="w-50" @click="closeSuccessModal">{{ $t('cancel') }}</CButton>
          </div>
            
          </template>
        </CModal>

        <!-- Sync Success Modal -->
        <CModal :show.sync="showSyncSuccess" centered>
          <div class="text-center">
            <CIcon name="cil-check-circle" size="4xl" class="text-success" />
            <h3 class="mt-3">Sync Success!</h3>
            <p>{{ $t('pleaseCheckProductSync') }}</p>
          </div>
          <template #footer>
          <div class="text-center w-100"><CButton color="success" class="w-50" @click="closeSuccessModal">{{ $t('submit') }}</CButton>
          </div>
             
        </template>
        </CModal>
        <CModal :show.sync="errorModel" centered color="danger" size="lg" >
          <div style="padding:5%;">
              <h3 class="text-center">{{ textError }}</h3>
          </div>
          <template #footer>
          <div class="text-center w-100"><CButton color="danger" class="w-50" @click="authorize">{{ $t('submit') }}</CButton>
          </div>
          </template>
        </CModal>
    </CCardBody>
    </CCard>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { CRow, CCol, CCard, CCardBody, CButton, CModal, CFormGroup, CInputCheckbox, CLabel } from "@coreui/vue";
import permis from "@/util/permission";
import ecommerce  from "@/services/ecommerce";
import moment from "moment";

export default {
  components: {
    CRow,
    CCol,
    CCard,
    CCardBody,
    CButton,
    CModal,
    CFormGroup,
    CInputCheckbox,
    CLabel,
  },
  data() {
    return {
      textError: '',
      errorModel: false,
      syncProduct: false,
      syncOrder: false,
      syncStock: false,
      showSyncLoading: false,
      showSyncSuccess: false,
      isConnected: false,
      enterConnect: false, 
      loadingButton: true,
      storeInfo: {},
      marketplaces: [
        {
          name: "Shopee",
          description: this.$t('shopeeDesc'),
          src: '/img/shopee.png'
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["shops", "shopsetting"]),
    isPermission() {
      return permis.findPermissionRead("setting", this.$route.path);
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    
  },
  created(){
    this.shop = this.shops.find((i) => this.shopObjectId === i.objectId)
    this.refreshToken()
  },
  mounted() {
  if (this.shop.connectEcommerce === undefined || this.shop.connectEcommerce != true) {
    this.$router.push('/dashboard');
    }
  },
  methods: {
    refreshToken() {
      const shopObjectId = this.shopObjectId
      const params = {
        shopObjectId: shopObjectId,
      }
      
      ecommerce({
        url: "/api/v1.0/shopee/token/refresh",
        params: params,
        method: "GET",
      })
        .then((response) => {
          if(response.data.error.message != "success"){
            this.errorModel = true
            this.textError = this.$t('systemError')
            this.isConnected = false
          } else if(response.data.data.message == "It should have refresh_token in the request body."){
            this.errorModel = true
            this.textError = this.$t('systemError')
            this.isConnected = false
          } else if (response.data.data == []) {
            this.errorModel = true
            this.textError = this.$t('systemError')
            this.isConnected = false
          } else {
            const data = response.data.data
            this.isConnected = false
            this.storeInfo = {
              id: data.shop_id,
              token: data.access_token,
              region: this.$t('thailand'),
              authorizedBy: "Admin",
              expiredDate: moment.unix(data.expire_in).format('DD/MM/YYYY'),
            }
          }
        })
        .catch((error) => {
          console.log(error)
        });
    },
    handleMarketplaceClick() {
      if (this.shop && this.shop.shopee && this.shop.shopee.product !== undefined) {
        if (this.shop.shopee.product === true) {
          this.isConnected = true
        } else {
          this.enterConnect = true
        }
      } else {
        this.enterConnect = true
      }
    },
    syncData() {
      // this.showSyncLoading = true
      // this.isConnected = false
      this.refreshToken()
      setTimeout(() => {
        // this.showSyncLoading = false
        // this.showSyncSuccess = true;
      }, 5000); 
    },
    closeSuccessModal() {
      this.showSyncSuccess = false
    },
    authorize() {
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId
      const params = {
        shopObjectId: shopObjectId,
        uid: uid,
        app: 'product'
      }
      // const headers = {
      //   shopObjectId: shopObjectId,
      // }
      ecommerce({
        url: 'api/v1.0/shopee/signin/link',
        params: params,
        method: 'GET',
        // headers: headers,
      })
        .then((response) => {
          const url = response.data.data.url || '-'
          if (url !== '-') {
            window.open(url, '_blank'); // เปิด URL ในแท็บใหม่
          }
        })
        .catch((error) => {
          console.log(error)
        })
    

      // window.location.href = '/ConnectedSuccess';
    }
  },
};
</script>

<style scoped>
.text-center {
  text-align: center;
}
.mb-3 {
  margin-bottom: 1rem;
}
.mb-4 {
  margin-bottom: 1.5rem;
}
.p-3 {
  padding: 1rem;
}
.mt-4 {
  margin-top: 1.5rem;
}
.d-flex {
  display: flex;
}
.justify-content-between {
  justify-content: space-between;
}
.align-items-center {
  align-items: center;
}
</style>
